import React from 'react';
import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import MyQRCodePage from './pages/MyQRCodePage';
import GenerateQRCodePage from './pages/GenerateQRCodePage';
import QRCodeStatsPage from './pages/QRCodeStatsPage'; 
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'



const supabase = createClient('https://ygzocglzsiilbeezuori.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inlnem9jZ2x6c2lpbGJlZXp1b3JpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjg4OTAzNzUsImV4cCI6MjA0NDQ2NjM3NX0.DFaj2U9p4dvESnPSKvXRZu2sK-ovPZ1LK79DXcQJxzI')

const customTheme = {
  default: {
    colors: {
      brand: 'hsl(265, 52%, 47%)',              // Dark purple for primary elements
      brandAccent: 'hsl(265, 48%, 35%)',        // Darker purple for hover/focus states
      brandButtonText: '#ffffff',               // White text for buttons
      inputBackground: '#ffffff',               // Dark background for input fields
      inputText: '#333333',                     // White input text
      inputBorder: '#444444',                   // Slightly lighter dark for input borders
      inputLabelText: '#ffffff',                // Light gray for label text
      messageTextDanger: 'hsl(355, 65%, 45%)',  // Red for error messages
      defaultButtonBackground: 'hsl(265, 52%, 47%)',  // Dark purple for buttons
      defaultButtonBackgroundHover: 'hsl(265, 48%, 35%)', // Hover effect for buttons
      defaultButtonText: '#ffffff',             // Button text in white
      messageText: '#ffffff',                   // Message text in white
      background: '#1a1a1a',                    // Very dark background for the form container
    },
    fonts: {
      bodyFontFamily: '"Inter", sans-serif',     // Modern font "Inter"
      headingFontFamily: '"Inter", sans-serif',  // Modern font for headings
      buttonFontFamily: '"Inter", sans-serif',   // Button font
    },
    fontSizes: {
      baseBodySize: '16px',                     // Base font size for text
      baseHeadingSize: '18px',                  // Font size for headings
      baseInputSize: '16px',                    // Input font size
      baseLabelSize: '14px',                    // Label font size
      baseButtonSize: '16px',                   // Button font size
    },
    borders: {
      inputBorderRadius: '1px',                 // Rounded corners for input fields
      buttonBorderRadius: '1px',                // Rounded corners for buttons
    },
  },
};


const App = () => {

  const [session, setSession] = useState(null)

    useEffect(() => {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session)
      })

      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session)
      })

      return () => subscription.unsubscribe()
    }, [])

    if (!session) {
      return (<Auth supabaseClient={supabase} appearance={{ theme: customTheme }} providers={[]} />)
    }
    else {
      return (
        <Router>
          <div className="app">
            <Sidebar />
            <div className="content">
              <Routes>
                <Route path="/my-qrcodes" element={<MyQRCodePage />} />
                <Route path="/generate-qrcode" element={<GenerateQRCodePage />} />
                <Route path="/stats/:id" element={<QRCodeStatsPage />} />
              </Routes>
            </div>
          </div>
        </Router>
      );
    }
  
};

export default App;