import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../components/Authentication_Supabase';
import '../style/QRCodeStatsPage.css'; // Add CSS for styling

const QRCodeStatsPage = () => {
  const { id } = useParams(); // Get the QR code ID from the URL
  const [qrCode, setQrCode] = useState(null);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const { data, error } = await supabase
          .from('urls')
          .select('*')
          .eq('url_id', id)
          .single();

        if (error) throw error;
        setQrCode(data);
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchLogs = async () => {
      try {
        const { data, error } = await supabase
          .from('logs')
          .select('*')
          .eq('url_id', id);

        if (error) throw error;
        setLogs(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQRCode();
    fetchLogs();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="qr-code-stats-page">
      {qrCode && (
        <div className="qr-code-info">
          <img src={`https://generate.getqrtrak.com/qrcode-image/${qrCode.url_id}`} alt="QR Code" className="qr-code-image" />
          <div className="qr-code-details">
            <p><strong>Date of Creation:</strong> {qrCode.creation_time}</p>
            <p><strong>Destination URL:</strong> {qrCode.destination}</p>
            <p><strong>Redirect URL:</strong> {`https://redirect.getqrtrak.com/redirect/${qrCode.url_id}`}</p>
          </div>
        </div>
      )}
      <div className="logs-table-container">
        <h2>Logs</h2>
        <table className="logs-table">
          <thead>
            <tr>
              <th>Time</th>
              <th>IP</th>
              <th>GPS Lat</th>
              <th>GPS Lon</th>
              <th>Browser</th>
              <th>OS</th>
              <th>Device</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => (
              <tr key={log.id}>
                <td>{new Date(log.time).toLocaleString()}</td>
                <td>{log.ip}</td>
                <td>{log.gps_lat}</td>
                <td>{log.gps_lon}</td>
                <td>{log.browser}</td>
                <td>{log.os}</td>
                <td>{log.device}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QRCodeStatsPage;