import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {supabase} from './Authentication_Supabase';


const Sidebar = () => {

  const history = useNavigate(); // For redirecting after sign out

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      history('/'); // Redirect to login or home page after signing out
    } else {
      console.error('Error signing out:', error.message);
    }
  };

  return (
    <div className="sidebar">
      <ul>
        <li><Link to="/my-qrcodes">My QR Codes</Link></li>
        <li><Link to="/generate-qrcode">Generate QR Code</Link></li>
        <li><Link to="/stats-qrcode">QR Code - Stats</Link></li>
        <li><button onClick={handleSignOut}>Sign Out</button></li> {/* Sign Out button */}
      </ul>
    </div>
  );
};

export default Sidebar;