import React from 'react';
import QRCodeList from '../components/QRCodeList';

const MyQRCodePage = () => {
  return (
    <div>
      <QRCodeList />
    </div>
  );
};

export default MyQRCodePage;