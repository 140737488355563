import React, { useState, useEffect } from 'react';
import { fetchQRCodes, deleteQRCode, updateQRCode, updateQRCodeStatus } from '../services/qrCodeService';
import '../style/QRCodeList.css'; // Import the CSS for styling
import { FaPen, FaCheck, FaTimes } from 'react-icons/fa'; // For icons
import { Link } from 'react-router-dom'; // Import Link from react-router-dom


const QRCodeList = () => {
  const [qrCodes, setQRCodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingId, setEditingId] = useState(null); // Track the ID of the QR code being edited
  const [editedUrl, setEditedUrl] = useState(''); // Hold the edited URL temporarily
  const [showDisabled, setShowDisabled] = useState(false); // Toggle to show/hide disabled QR codes

  useEffect(() => {
    async function loadQRCodes() {
      try {
        const data = await fetchQRCodes();
        setQRCodes(data.fetched_records);
      } catch (error) {
        console.error('Error fetching QR codes:', error);
        setQRCodes([]); // Handle error by setting empty array
      }
    }
    loadQRCodes();
  }, []);

  const handleDelete = async (url_id) => {
    await deleteQRCode(url_id);
    setQRCodes(qrCodes.filter((qr) => qr.url_id !== url_id));
  };

  const handleUpdate = async (url_id) => {
    await updateQRCode(url_id, editedUrl);
    const updatedQR = qrCodes.map((qr) => qr.url_id === url_id ? { ...qr, destination: editedUrl } : qr);
    setQRCodes(updatedQR);
    setEditingId(null); // Exit editing mode
  };

  const handleCancelEdit = () => {
    setEditingId(null); // Cancel editing
  };

  const handleToggleStatus = async (url_id, currentStatus) => {
    const newStatus = !currentStatus;
    await updateQRCodeStatus(url_id, newStatus); // Update status in the database
    const updatedQR = qrCodes.map((qr) => qr.url_id === url_id ? { ...qr, status_link: newStatus } : qr);
    setQRCodes(updatedQR); // Update status in the state
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filter QR codes based on search term and showDisabled flag (default: no disabled QR codes)
  const filteredQRCodes = qrCodes.filter((qr) =>
    (qr.destination.toLowerCase().includes(searchTerm) || `https://redirect.getqrtrak.com/redirect/${qr.url_id}`.toLowerCase().includes(searchTerm))
    && (showDisabled || qr.status_link) // Exclude disabled codes unless showDisabled is true
  );


  console.log('QR Codes State:', qrCodes);  
  return (
    <div className="qr-code-list">
      <h2>My QR Codes</h2>

      <input
        type="text"
        className="search-input"
        placeholder="Search by URL or Destination"
        value={searchTerm}
        onChange={handleSearch}
      />

      {/* Toggle disabled QR codes */}
      <button
        className="toggle-disabled-button"
        onClick={() => setShowDisabled(!showDisabled)}
      >
        {showDisabled ? 'Hide Disabled QR Codes' : 'Show Disabled QR Codes'}
      </button>

      <div className="qr-code-cards">
        {filteredQRCodes.map((qr) => (
          <div className="qr-card" key={qr.url_id}>
            <div className="qr-card-image">
              <img src={`https://generate.getqrtrak.com/qrcode-image/${qr.url_id}`} alt="QR Code" />
            </div>
            <div className="qr-card-info">
              <p><strong>QR Code URL:</strong> {`https://redirect.getqrtrak.com/redirect/${qr.url_id}`}</p>
              <p><strong>Date of Creation:</strong> {qr.creation_time}</p>
              <p><strong>Status:</strong> {qr.status_link ? 'enabled' : 'disabled'}</p>
              <p><strong>Destination URL:</strong> {qr.destination}</p>
              <Link to={`/stats/${qr.url_id}`} className="stats-button">Stats</Link> {/* Add Stats button */}
            </div>
            <div className="qr-card-actions">
              <button
                className={`toggle-status-button ${qr.status_link ? 'disable' : 'enable'}`}
                onClick={() => handleToggleStatus(qr.url_id, qr.status_link)}
              >
                {qr.status_link ? 'Disable' : 'Enable'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QRCodeList;
