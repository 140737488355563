import React, { useState, useEffect } from 'react';
import { generateQRCode } from '../services/qrCodeService';
import '../style/QRCodeGenerator.css'; // Add CSS file for styling

const QRCodeGenerator = () => {
  const [originalUrl, setOriginalUrl] = useState('');
  const [qrData, setQrData] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const [progress, setProgress] = useState(100); // Progress bar for message

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await generateQRCode(originalUrl);
      setQrData(data); // Fix: Set QR data to display it correctly
      setMessage(`QR code generated for ${data.originalUrl}`);
      setMessageType('success');
    } catch (err) {
      setMessage(`Error: ${err.message}`);
      setMessageType('error');
    }
    setOriginalUrl(''); // Clear the input field after submission
  };

  // Hide the message after 7 seconds and decrease the progress bar
  useEffect(() => {
    if (message) {
      setProgress(100); // Reset the progress bar each time a new message appears
      const timer = setInterval(() => {
        setProgress((prev) => (prev > 0 ? prev - 1 : 0)); // Decrease progress bar
      }, 70); // Decrease the progress bar over 7 seconds

      const hideMessage = setTimeout(() => {
        setMessage(null);
      }, 7000);

      return () => {
        clearTimeout(hideMessage);
        clearInterval(timer);
      };
    }
  }, [message]);

  return (
    <div className="qr-generator-container">
      <h2 className="page-title">Generate Your QR Code</h2>
      <p className="service-description">
        This service allows you to generate a QR code for any URL. Enter the destination URL, and we'll generate a unique QR code that you can use to easily share the link. Once generated, the QR code will redirect users to the specified URL when scanned.
      </p>

      {/* Success/Failure Message */}
      {message && (
        <div className={`message-box ${messageType}`}>
          {message}
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}

      {/* Form */}
      <form onSubmit={handleSubmit} className="qr-form">
        <input
          type="text"
          className="url-input"
          placeholder="Enter the destination URL"
          value={originalUrl}
          onChange={(e) => setOriginalUrl(e.target.value)}
          required
        />
        <button type="submit" className="generate-button">Generate QR Code</button>
      </form>

      {/* Display the generated QR code */}
      {qrData && (
        <div className="qr-result">
          <img src={`data:image/png;base64,${qrData.qrCode}`} alt="Generated QR Code" />
          <p><strong>QR Code URL:</strong> {qrData.controlledUrl}</p>
          <p><strong>Destination URL:</strong> {qrData.originalUrl}</p>
        </div>
      )}
    </div>
  );
};

export default QRCodeGenerator;
