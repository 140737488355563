import React from 'react';
import QRCodeGenerator from '../components/QRCodeGenerator';

const GenerateQRCodePage = () => {
  return (
    <div>
      <QRCodeGenerator />
    </div>
  );
};

export default GenerateQRCodePage;